import React, {FC} from 'react';
import { connect } from 'react-redux';


const createTheme = (theme: any, backgroundUrl:string) => {
  if (!theme) return '';
  const color = theme as any;
  console.log('wut', backgroundUrl)
  return `
  body {
    background-image: url(${backgroundUrl});

    background-color: ${color.background};
    color: ${color.foreground};
  }

  .footer-nav svg.icon .main-fill {
    fill: ${color.foregroundTransparent};
  }
  .footer-nav .button__label--icon {
    color: ${color.foregroundTransparent};
  }
  .footer-nav {
    background: ${color.backgroundTransparent};
  }

  h1, h2, h3 {
    color: ${color.titles};
  }

  p{
    color: ${color.foreground};
  }

  .homepage__icon .homepage__icon_label{
    color: ${color.foreground};
  }
  
  svg.icon--t-filled .t-foreground-fill{
   fill: ${color.foregroundInverted};
  }
  svg.icon--t-filled .t-box-fill{
    fill: ${color.backgroundInvertedIconBox};
   }
  svg.icon--t-filled .t-background-fill{
    fill: ${color.backgroundInverted};
  }
  
  svg.icon--t-filled .t-border-fill {
    fill: ${color.backgroundInvertedBorder};
  }

  .box--short.box--themed, a.link-box--themed{
    background: ${color.interactive};
  }
  a:hover .box--short.box--themed, a:hover.link-box--themed  {
    background: ${color.hover};
  }
  a:active .box--short.box--themed, a:active.link-box--themed  {
    background: ${color.active};
  }

  .box--short.box--themed .categorytext {
    color: ${color.interactiveInvertedForeground};
  }

  .box--short.box--themed .main-fill{
    fill: ${color.interactiveInvertedForeground};
  }

  .box--short.box--themed-secondary {
    background: ${color.interactiveSecondary};
  }
  a:hover .box--short.box--themed-secondary  {
    background: ${color.hover};
  }
  a:active .box--short.box--themed-secondary  {
    background: ${color.active};
  }
  .box--short.box--themed-secondary .organizationtext {
    color: ${color.interactiveInvertedForeground};
  }

  .button.button.button--primary {
    background: ${color.interactive};
    color: ${color.interactiveInvertedForeground};
  }
  .button:hover.button.button--primary {
    background: ${color.hover};
    color: ${color.interactiveInvertedForeground};

  }
  .button:active.button.button--primary {
    background: ${color.active};
    color: ${color.interactiveInvertedForeground};
  }
  .button.button.button--primary .main-fill{
    fill: ${color.interactiveInvertedForeground};
  }

  .search-btn {
    fill: ${color.interactiveInvertedForeground};
    background: ${color.active};
  }
  .search-btn .icon .main-fill{
    fill: ${color.interactiveInvertedForeground};
  }
  input[type=search] {
    color: ${color.active};
    border-color: ${color.active};
    background: ${color.backgroundSecondary};
  }
  ::placeholder{
    color: ${color.placeholder};
  }
  .header-top-right-menu .icon .main-fill {
    fill: ${color.foreground}
  }
`;
}

  interface Props {
    theme: any;
    config: any;
  }

export const Theme: FC<Props> = ({ theme, config }) => {
  const { backgroundUrl } = config;
  if (!theme) return null;
  return <style>{createTheme(theme, backgroundUrl)}</style>
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    theme: state.data.theme || { background: '#666' } as any,
    config: ownProps.config
  }
}

export default connect(mapStateToProps)(Theme);